import styled from 'styled-components';
import { Colors, FontSizeRem, GridRem } from '../../dls/Styleguide';

export const StyledLeftContent = styled.div`
  max-width: 20rem;
`;

export const StyledLoginPage = styled.div`
  margin-top: ${GridRem.Gridx64};

  @media all and (max-width: 45em) {
    margin-top: ${GridRem.Gridx32};
  }

  @media all and (max-width: 25em) {
    margin-top: ${GridRem.Gridx16};
  }
`;

export const StyledLoginPageContainer = styled.div`
  display: flex;
  flex: 1;
  margin: 0 ${GridRem.Gridx64};
  padding: ${GridRem.Gridx32} 0;
  justify-content: space-between;

  @media all and (max-width: 45em) {
    margin: 0 ${GridRem.Gridx32};
  }

  @media all and (max-width: 25em) {
    margin: 0 ${GridRem.Gridx16};
  }
`;

export const StyledESIMInfoContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  margin: ${GridRem.Gridx32} 0;
`;

export const StyledElectronicIdText = styled.div`
  color: ${Colors.Beige};
  margin-bottom: ${GridRem.Gridx32};
  margin-top: ${GridRem.Gridx32};
  font-size: ${FontSizeRem.BodyLarge};
  width: 30rem;

  @media all and (max-width: 45em) {
    max-width: 19rem;
  }
`;

export const StyledAudurLogo = styled.div`
  margin-left: 3.5rem;

  @media all and (max-width: 45em) {
    margin-left: 1.5rem;
  }

  @media all and (max-width: 25em) {
    margin-left: ${GridRem.Gridx8};
  }
`;
